import React, { useEffect } from 'react';
import Head from '@components/Head';
import Page from '@components/Page';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import * as featuredPropertiesActions from '@redux/modules/featuredProperties';
import * as neighborPlanActions from '@redux/modules/neighborPlans';
import * as signupLimitActions from '@redux/modules/signupLimit';
import reduxWrapper from '@redux/store';
import JpTopMain from '@templates/JpTopMain';
import TopMain from '@templates/TopMain';
import { PUBLIC_IMAGES_URL } from '@utils/constants';
import { useActions } from '@utils/hooks';
import routes from '@utils/routes';
import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const IndexPage = ({ locale }: { locale: LANG_LOCALE }): JSX.Element | null => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { isJapan } = useLocation();
  const { authUser, loadingAuthUser, loadingNeighbor, neighbor } =
    useAppSelector((state) => ({
      authUser: state.authUser,
      loadingAuthUser: state.loadingAuthUser,
      loadingNeighbor: state.loadingNeighbor,
      neighbor: state.neighbor,
    }));
  const { getNeighborPlans } = useActions({
    ...neighborPlanActions,
  });

  useEffect(() => {
    if (!loadingAuthUser && !loadingNeighbor && !isEmpty(neighbor)) {
      router.replace(routes.browse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAuthUser, loadingNeighbor]);

  useEffect(() => {
    getNeighborPlans(locale, isEmpty(authUser) ? null : authUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <Page isHeaderLogoH1={true}>
      <Head
        image={
          locale === 'ja'
            ? `${PUBLIC_IMAGES_URL}/images/jp-top/ogp.png`
            : `${process.env.NEXT_PUBLIC_GCS_URL}/ogp/common.jpg`
        }
        title={t('meta.title')}
        type="website"
      />
      {isJapan ? <JpTopMain /> : <TopMain />}
    </Page>
  );
};

export const getServerSideProps = reduxWrapper.getServerSideProps(
  (_store) =>
    async ({ locale, req }) => {
      await Promise.all([
        _store.dispatch(
          featuredPropertiesActions.getFeaturedProperties(
            locale,
            req.headers['x-hafh-client-ip']
          )
        ),
        _store.dispatch(signupLimitActions.getSignupLimit(locale)),
      ]);

      const { hafhLoggedIn } = req.cookies;

      if (hafhLoggedIn === 'true') {
        return {
          redirect: {
            destination: `/${locale}/${routes.browse}`,
            permanent: false,
          },
        };
      }

      return {
        props: {
          locale,
        },
      };
    }
);

export default IndexPage;
