import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .campaign-top-hero-section {
    .campaign-hero-content {
      position: relative;
      .campaign-hero-button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 162px;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
        @media screen and (min-width: ${BREAKPOINT.desktopXl}px) {
          width: 218px;
          bottom: 27px;
        }
      }
    }
    .message-wrapper {
      background-color: ${COLORS.purple800};
      padding: 56px 32px;
      text-align: center;
    }
  }
`;

export default styles;
