import { TypoLBold, TypoMBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import { useActiveCoinPack } from '@hooks/useActiveCoinPack';
import { useCampaignOnClickPushDataLayer } from '@hooks/useCampaign';
import BannerSlider from '@molecules/BannerSlider';
import { COIN_PACK_CAMPAIGN_TOPIC_ID } from '@utils/constants';
import routes, { route } from '@utils/routes';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const TopBanners = () => {
  const { t } = useTranslation('common');
  const { activeCoinPackImagePath, showCoinPackPreAnnouncement } =
    useActiveCoinPack();
  const campaignOnClickPushDataLayer = useCampaignOnClickPushDataLayer();

  const onClickCoinPackBanner = () => {
    campaignOnClickPushDataLayer({
      event_name: 'banner_coinpack',
      type: 'coinpack',
    });
  };

  const topBanners = [
    ...(showCoinPackPreAnnouncement
      ? [
          {
            alt: 'pre coin package',
            image_url: `${activeCoinPackImagePath}pre-banner.png?v=3`,
            link: route(routes.topic, {
              id: COIN_PACK_CAMPAIGN_TOPIC_ID,
            }),
            onClick: () => onClickCoinPackBanner(),
          },
        ]
      : []),
  ];

  if (!topBanners?.length) {
    return null;
  }

  const title = t('news');

  return (
    <>
      <div className="campaign-banners">
        <div className="campaign-banners-title">
          <Mobile>
            <TypoMBold text={title} />
          </Mobile>
          <Desktop>
            <TypoLBold text={title} />
          </Desktop>
        </div>
        <div className="campaign-banner">
          <Desktop>
            {topBanners.map(({ alt, image_url, link }) => (
              <div key={link}>
                <Link href={link}>
                  <Image alt={alt} height={123} src={image_url} width={343} />
                </Link>
              </div>
            ))}
          </Desktop>
          <Mobile>
            <BannerSlider banners={topBanners} />
          </Mobile>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default TopBanners;
